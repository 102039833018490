.form-action {
    background-color: white;
    border-radius: 8px;
    color: #07B9AD;
    width: 100%;
    display: block;
    text-align: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
}

.button-link {
    margin-top: 25px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: white;
    text-decoration: underline;
}

.button-link-in {
    color: white;
    text-decoration: underline;
}
