.Detalhes {
    padding-top: 126px;
}

.Detalhes .seccao {
    padding: 30px 0;
}

.Detalhes .seccao.green {
    background-color: #07b9ad;
    color: white;
}

.Detalhes .seccao.purple {
    background-color: #788FBF;
    color: white;
}

.Detalhes .seccao .sub-title {
    color: white;
    font-weight: 500;
}

.Detalhes .seccao .sub-title-bold {
    color: white;
    font-weight: 800;
}

/* CABEÇALHO */

.Detalhes .CabecalhoInicio {
    background-color: white;
    color: #A5A5A5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.Detalhes .CabecalhoInicio .topo {
    padding: 15px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
}

.Detalhes .CabecalhoInicio .topo h4 {
    flex: 1;
    font-weight: 300;
    font-size: 14px;
    margin: 8px;
}

.Detalhes .CabecalhoInicio .topo .avatar, .Detalhes .CabecalhoInicio .topo .pesquisar {
    width: 50px;
    height: 50px;
    border: white solid 2px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
}

.Detalhes .CabecalhoInicio .topo .avatar {
    border-width: 2px;
    background-size: cover;
}

.Detalhes .CabecalhoInicio .topo .pesquisar {
    border-width: 1px;
    background-size: 50%;
    cursor: pointer;
}

.Detalhes .CabecalhoInicio .topo .pesquisar:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Detalhes .CabecalhoInicio .Separadores {
    overflow: auto;
    margin-top: 7px;
    margin-bottom: 7px;
}

.Detalhes .CabecalhoInicio .Separadores > div {
    white-space: nowrap;
}

.Detalhes .CabecalhoInicio .Separadores > div li {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: pointer;
}

.Detalhes .CabecalhoInicio .Separadores > div li span {
    padding-left: 10px;
    padding-right: 10px;
    color: #A5A5A5;
    font-weight: 500;
}

.Detalhes .CabecalhoInicio .Separadores > div li:first-child {
    padding-left: 15px;
}

.Detalhes .CabecalhoInicio .Separadores > div li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Detalhes .CabecalhoInicio .Separadores > div li.active {
    font-weight: bold;
    color: #07b9ad;
}

/* Criar Viagem */

/* CATEGORIAS */

.Detalhes .categorias {
    background-color: #07b9ad;
    color: white;
    padding: 20px;
    padding-bottom: 0;
}

.Detalhes .categorias h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.Detalhes .categorias .categoria {
    width: 50%;
    display: inline-block;
    cursor: pointer;
}

.Detalhes .categorias .categoria:hover {
    opacity: 0.85;
}

.Detalhes .categorias .categoria span {
    height: 120px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px;
}

/* Input */

.Detalhes input {
    width: 90%;
    display: inline;
    margin-bottom: 15px;
    background: unset;
    border: none;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    font-weight: 300;
    padding: 6px 0;
    font-size: 15px;
    -webkit-appearance: none;
    border-radius: 0;
}

.Detalhes .pesquisar {
    margin-bottom: -20px;
    width: 10%;
    height: 50px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
}

.Detalhes input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Detalhes input::selection {
    color: white;
    background-color: #87b9a2;
}

.Detalhes label.checkbox {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    color: white;
}

.Detalhes input[type="checkbox"] {
    height: 18px;
    margin: 0;
    width: 18px;
    display: inline;
}

.Detalhes .collapsible .header {
    display: flex;
    padding: 16px 10px;
    align-items: center;
    border-bottom: 1px solid white;
    border-width: 0.5px;
    cursor: pointer;
}

.Detalhes .collapsible .header h3 {
    color: white;
    font-weight: 200;
    font-size: 16px;
    flex: 1;
}

.Detalhes .collapsible .header img {
    width: 16px;
    opacity: 0.9;
    transition: all 0.3s;
}

.Detalhes .collapsible .is-open .header img {
    transform: scaleY(-1);
    margin-bottom: 3px;
}

.Detalhes .collapsible__contentInner {
    padding: 15px;
}

/* Title Top */
.Detalhes .title-top {
    margin-top: 15px;
    color: #788FBF;
    text-transform: uppercase;
}

.Detalhes .sub-title-top {
    color: #07B9AD;
    font-weight: 800;
    font-size: 23px;
    margin-bottom: 15px;
}

.Detalhes .video_header {
    position: relative;
    padding-bottom: 56.25%;
}

.Detalhes .video_header iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.Detalhes .detalhes_local {

}

.Detalhes .detalhes_local label {
    text-transform: capitalize;
    margin-top: 20px;
    font-size: 16px;
    color: #788FBF
}

.Detalhes .detalhes_local h5 {
    font-size: 30px
}

.Detalhes .detalhes_local p {
    text-transform: none;
    margin-bottom: 35px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.9;
}

.Detalhes .preco > div {
    width: 300px;
    margin: auto;
    background-color: white;
    border-radius: 50%;
    position: relative;
}

.Detalhes .preco > div .background {
    padding-bottom: 100%;
}

.Detalhes .preco .content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    padding-bottom: 60px;
}

.Detalhes .preco .content p {
    color: #07B9AD;
    text-align: center;
    margin: 0;
}

.Detalhes .preco .content a {
    border-radius: 10px;
    margin-top: 30px;
    color: white;
    padding: 15px;
    background-color: #07b9ad;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
}