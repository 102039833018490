@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
.FadeView {

}

.FadeView > .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.FadeView > .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.FadeView > .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.BottomTabNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    background-color: #f3f2ed;
    z-index: 2;
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    border-top: #07B9AD 2px solid;
    min-height: 55px;
}

.BottomTabNavigation .BottomTabNavigationButton {
    flex: 1 1;
    text-align: center;
    vertical-align: middle;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #788fbf;
    cursor: pointer;
    text-decoration: none;
}

.BottomTabNavigation .BottomTabNavigationButton:hover {
    background-color: rgba(1, 17, 70, 0.02);
}

.BottomTabNavigation .BottomTabNavigationButton:hover, .BottomTabNavigation .BottomTabNavigationButton.active {
    color: #07B9AD;
}

.BottomTabNavigation .BottomTabNavigationButton i {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: auto 20px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: auto 20px;
    background-color: #788fbf;
    display: block;
    height: 20px;
    margin-bottom: 8px;
}

.BottomTabNavigation .BottomTabNavigationButton.active i, .BottomTabNavigation .BottomTabNavigationButton:hover i {
    background-color: #07B9AD;
}

.BottomTabNavigation .BottomTabNavigationButton span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
}
.Splashscreen {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: stretch;
    background-color: #07b9ad;
}

.Splashscreen .center {
    flex: 1 1;
}

.Splashscreen .center > div {
    width: 75%;
    margin: auto;
}

.Splashscreen .logo {
    width: 90%;
    margin: auto;
    position: relative;
    padding-bottom: 100%;
}

.Splashscreen .logo div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-animation: rotating 140s linear infinite;
    animation: rotating 140s linear infinite;
}

.Splashscreen .logo div:first-child {
    -webkit-animation: rotating 200s linear infinite;
    animation: rotating 200s linear infinite;
    animation-direction: reverse;
}

.Splashscreen .bem_haja {
    width: 50%;
    margin: auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}


@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
.Apresentacao {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #07b9ad;
    flex-direction: column;
}

.Apresentacao .wrapper {
    width: 100%;
    height: 100%;
}

.Apresentacao .itemsWrapper, .Apresentacao .itemsWrapper > div {
    height: 100%;
}

.Apresentacao .itemsWrapper .itemWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.Apresentacao .itemsWrapper .itemWrapper .Slide {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Apresentacao .itemsWrapper .itemWrapper .image {
    flex: 1 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding-bottom: 50px;
}

.Apresentacao .itemsWrapper .itemWrapper .image > div {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex: 1 1;
}

.Apresentacao .itemsWrapper .itemWrapper h3, .Apresentacao .itemsWrapper .itemWrapper p {
    text-align: center;
    color: white;
}

.Apresentacao .itemsWrapper .itemWrapper h3 {
    text-transform: uppercase;
    font-size: 25px;
    letter-spacing: 0.02em;
}

.Apresentacao .itemsWrapper .itemWrapper p {
    font-size: 16px;
    margin-top: 10px;
}

.Apresentacao .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
}
.Bullets {
    margin-top: 15px;
    text-align: center;
}

.Bullets .bullet {
    width: 13px;
    height: 13px;
    display: inline-block;
    border: solid 1px white;
    border-radius: 50%;
    background-color: white;
    margin: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    cursor: pointer;
}

.Bullets .bullet.active {
    background-color: transparent !important;
}
.OnBoarding {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: #07b9ad;
    flex-direction: column;
    padding-top: 50px;
}

.OnBoarding .wrapper {
    flex: 1 1;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.OnBoarding .logo {
    height: 160px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 15px;
}

.OnBoarding .wrapper .title {
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 20px;
}

.OnBoarding .wrapper .form {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.OnBoarding .wrapper form input {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    background: unset;
    border: none;
    border-bottom: 1px solid white;
    text-transform: uppercase;
    color: white;
    outline: none;
    font-weight: 300;
    padding: 6px 0;
    font-size: 15px;
    -webkit-appearance: none;
    border-radius: 0;
}

.OnBoarding .wrapper form input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.OnBoarding .wrapper form input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.OnBoarding .wrapper form input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.OnBoarding .wrapper form input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.OnBoarding .wrapper form input::selection {
    color: white;
    background-color: #87b9a2;
}

.OnBoarding .wrapper form label.checkbox {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    color: white;
}

.OnBoarding .wrapper form input[type="checkbox"] {
    height: 18px;
    margin: 0;
    width: 18px;
    display: inline;
}

.auth-label {
    display: block;
    text-align: center;
    font-size: 14px;
    color: white;
    margin-top: 25px;
}

.OnBoarding .wrapper form input[type=checkbox] {
    position: absolute;
    left: -9999px;
}

.OnBoarding .wrapper form input[type=checkbox] + span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.OnBoarding .wrapper form input[type=checkbox] + span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    width: 18px;
    height: 18px;
    border: 1px solid white;
}

.OnBoarding .wrapper form input[type=checkbox] + span:after {
    content: "";
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 6px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.OnBoarding .wrapper form input[type=checkbox]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.OnBoarding .wrapper form input[type=checkbox]:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.Inicio {
    padding-top: 75px;
    padding-bottom: env(safe-area-inset-bottom);
}

.Inicio .seccao {
    padding: 30px 0;
}

/* CABEÇALHO */

.Inicio .CabecalhoInicio {
    background-color: #07b9ad;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.Inicio .CabecalhoInicio .topo {
    padding: 15px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.Inicio .CabecalhoInicio .topo h4 {
    flex: 1 1;
    font-weight: 300;
    font-size: 14px;
    margin: 8px;
}

.Inicio .CabecalhoInicio .topo .avatar, .Inicio .CabecalhoInicio .topo .pesquisar {
    width: 50px;
    height: 50px;
    border: white solid 2px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
}

.Inicio .CabecalhoInicio .topo .avatar {
    border-width: 2px;
    background-size: cover;
}

.Inicio .CabecalhoInicio .topo .pesquisar {
    border-width: 1px;
    background-size: 50%;
    cursor: pointer;
}

.Inicio .CabecalhoInicio .topo .pesquisar:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Inicio .Filtros {
    overflow: auto;
    background-color: #07b9ad;
    color: white;
}

.Inicio .Filtros > div {
    white-space: nowrap;
}

.Inicio .Filtros > div li {
    list-style: none;
    padding: 10px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: pointer;
}

.Inicio .Filtros > div li:first-child {
    padding-left: 15px;
}

.Inicio .Filtros > div li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Inicio .Filtros > div li.active {
    font-weight: bold;
}

/* MAPA */
.Inicio .mapa {
    height: 400px;
}

/* CATEGORIAS */

.Inicio .categorias {
    background-color: #07b9ad;
    color: white;
    padding: 20px;
    padding-bottom: 0;
}

.Inicio .categorias h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.Inicio .categorias .categoria {
    width: 50%;
    display: inline-block;
    cursor: pointer;
}

.Inicio .categorias .categoria:hover {
    opacity: 0.85;
}

.Inicio .categorias .categoria span {
    height: 120px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px;
}
.Caroussel {
    text-align: center;
    overflow: hidden;
}

.Caroussel.default .wrapper {
    width: 70%;
}

.Caroussel.special .wrapper {
    width: 85%;
}

.Caroussel .itemsWrapper {
    overflow-x: visible;
    overflow-x: initial;
}

.Caroussel .Card {
}

.Caroussel .Card.not_current {
    pointer-events: none;
}

.Caroussel .Card .image {
    position: relative;
    padding-bottom: 56%;
    background-position: center;
    background-size: cover;
}

.Caroussel.image .Card .image {
    padding-bottom: 70%;
}

.Caroussel .Card.special .image:after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    background: radial-gradient(circle, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.2) 100%);;
    position: absolute;
}

.Caroussel .Card h5 {
    text-align: left;
    margin-top: 7px;
}

.Caroussel .Card p {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
}

.Caroussel .Card.special h5 {
    font-size: 16px;
    margin-top: 8px;
}

.Caroussel .Card.special p {
    font-size: 13px;
    margin-top: 3px;
}

.Caroussel .Card.special .Estrelas {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
}

.Caroussel .Card.special .Estrelas img {
    display: inline-block;
    margin: 0 3px;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.Caroussel .Card.special .botao_favorito {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 1;
}
.PontoMapa {
    width: 30px;
    height: 40px;
    position: relative;
    top: -40px;
    left: -15px;
}
.Planear {
    padding-top: 47px;
    padding-bottom: env(safe-area-inset-bottom);
}

.Planear .seccao {
    padding: 30px 0;
}

.Planear .seccao.green {
    background-color: #07b9ad;
    color: white;
}

.Planear .seccao.purple {
    background-color: #788FBF;
    color: white;
}

.Planear .seccao .sub-title {
    color: white;
    font-weight: 500;
}

.Planear .seccao .sub-title-bold {
    color: white;
    font-weight: 800;
}

/* CABEÇALHO */

.Planear .Cabecalho {
    background-color: white;
    color: #A5A5A5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.Planear .Cabecalho .Separadores {
    overflow: auto;
    margin-top: 7px;
    margin-bottom: 7px;
}

.Planear .Cabecalho .Separadores > div {
    white-space: nowrap;
    text-align: center;
}

.Planear .Cabecalho .Separadores > div li {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: pointer;
}

.Planear .Cabecalho .Separadores > div li span {
    padding-left: 10px;
    padding-right: 10px;
    color: #A5A5A5;
    font-weight: 500;
}

.Planear .Cabecalho .Separadores > div li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Planear .Cabecalho .Separadores > div li.active {
    font-weight: bold;
    color: #07b9ad;
}

/* Criar Viagem */

/* CATEGORIAS */

.Planear .categorias {
    background-color: #07b9ad;
    color: white;
    padding: 20px;
    padding-bottom: 0;
}

.Planear .categorias h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.Planear .categorias .categoria {
    width: 50%;
    display: inline-block;
    cursor: pointer;
}

.Planear .categorias .categoria:hover {
    opacity: 0.85;
}

.Planear .categorias .categoria span {
    height: 120px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px;
}

.Planear .collapsible .header h3 {
    color: white;
    font-weight: 200;
    font-size: 14px;
    flex: 1 1;
}

.Planear .collapsible .header img {
    width: 20px;
    opacity: 0.9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.Planear .collapsible .is-open .header img {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
    margin-bottom: 3px;
}

.Planear .search_block {
    text-align: right;
}

.Planear .search_block > div {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.Planear .search_block p {
    margin: 0;
}

.Planear .search_block .pesquisar, .Planear .pesquisar_localizacao .pesquisar {
    background-position: center;
    background-repeat: no-repeat;
    height: 25px;
    background-size: contain;
    width: 25px;
    margin-left: 10px;
    cursor: pointer;
}

.Planear .pesquisar_localizacao .input_pesquisa {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.Planear .pesquisar_localizacao .input_pesquisa input {
    flex: 1 1;
}


.Planear .pesquisar_localizacao .input_pesquisa input {
    display: block;
    margin-bottom: 15px;
    background: unset;
    border: none;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    font-weight: 300;
    padding: 6px 0;
    font-size: 15px;
    -webkit-appearance: none;
    border-radius: 0;
}

.Planear .pesquisar_localizacao .input_pesquisa input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Planear .pesquisar_localizacao .input_pesquisa input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Planear .pesquisar_localizacao .input_pesquisa input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Planear .pesquisar_localizacao .input_pesquisa input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Planear .pesquisar_localizacao .input_pesquisa input::selection {
    color: white;
    background-color: #87b9a2;
}
.Detalhes {
    padding-top: 126px;
}

.Detalhes .seccao {
    padding: 30px 0;
}

.Detalhes .seccao.green {
    background-color: #07b9ad;
    color: white;
}

.Detalhes .seccao.purple {
    background-color: #788FBF;
    color: white;
}

.Detalhes .seccao .sub-title {
    color: white;
    font-weight: 500;
}

.Detalhes .seccao .sub-title-bold {
    color: white;
    font-weight: 800;
}

/* CABEÇALHO */

.Detalhes .CabecalhoInicio {
    background-color: white;
    color: #A5A5A5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.Detalhes .CabecalhoInicio .topo {
    padding: 15px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
}

.Detalhes .CabecalhoInicio .topo h4 {
    flex: 1 1;
    font-weight: 300;
    font-size: 14px;
    margin: 8px;
}

.Detalhes .CabecalhoInicio .topo .avatar, .Detalhes .CabecalhoInicio .topo .pesquisar {
    width: 50px;
    height: 50px;
    border: white solid 2px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
}

.Detalhes .CabecalhoInicio .topo .avatar {
    border-width: 2px;
    background-size: cover;
}

.Detalhes .CabecalhoInicio .topo .pesquisar {
    border-width: 1px;
    background-size: 50%;
    cursor: pointer;
}

.Detalhes .CabecalhoInicio .topo .pesquisar:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Detalhes .CabecalhoInicio .Separadores {
    overflow: auto;
    margin-top: 7px;
    margin-bottom: 7px;
}

.Detalhes .CabecalhoInicio .Separadores > div {
    white-space: nowrap;
}

.Detalhes .CabecalhoInicio .Separadores > div li {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: pointer;
}

.Detalhes .CabecalhoInicio .Separadores > div li span {
    padding-left: 10px;
    padding-right: 10px;
    color: #A5A5A5;
    font-weight: 500;
}

.Detalhes .CabecalhoInicio .Separadores > div li:first-child {
    padding-left: 15px;
}

.Detalhes .CabecalhoInicio .Separadores > div li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Detalhes .CabecalhoInicio .Separadores > div li.active {
    font-weight: bold;
    color: #07b9ad;
}

/* Criar Viagem */

/* CATEGORIAS */

.Detalhes .categorias {
    background-color: #07b9ad;
    color: white;
    padding: 20px;
    padding-bottom: 0;
}

.Detalhes .categorias h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.Detalhes .categorias .categoria {
    width: 50%;
    display: inline-block;
    cursor: pointer;
}

.Detalhes .categorias .categoria:hover {
    opacity: 0.85;
}

.Detalhes .categorias .categoria span {
    height: 120px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px;
}

/* Input */

.Detalhes input {
    width: 90%;
    display: inline;
    margin-bottom: 15px;
    background: unset;
    border: none;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    font-weight: 300;
    padding: 6px 0;
    font-size: 15px;
    -webkit-appearance: none;
    border-radius: 0;
}

.Detalhes .pesquisar {
    margin-bottom: -20px;
    width: 10%;
    height: 50px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
}

.Detalhes input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Detalhes input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Detalhes input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Detalhes input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.Detalhes input::selection {
    color: white;
    background-color: #87b9a2;
}

.Detalhes label.checkbox {
    display: flex;
    margin-top: 20px;
    font-size: 12px;
    color: white;
}

.Detalhes input[type="checkbox"] {
    height: 18px;
    margin: 0;
    width: 18px;
    display: inline;
}

.Detalhes .collapsible .header {
    display: flex;
    padding: 16px 10px;
    align-items: center;
    border-bottom: 1px solid white;
    border-width: 0.5px;
    cursor: pointer;
}

.Detalhes .collapsible .header h3 {
    color: white;
    font-weight: 200;
    font-size: 16px;
    flex: 1 1;
}

.Detalhes .collapsible .header img {
    width: 16px;
    opacity: 0.9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.Detalhes .collapsible .is-open .header img {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
    margin-bottom: 3px;
}

.Detalhes .collapsible__contentInner {
    padding: 15px;
}

/* Title Top */
.Detalhes .title-top {
    margin-top: 15px;
    color: #788FBF;
    text-transform: uppercase;
}

.Detalhes .sub-title-top {
    color: #07B9AD;
    font-weight: 800;
    font-size: 23px;
    margin-bottom: 15px;
}

.Detalhes .video_header {
    position: relative;
    padding-bottom: 56.25%;
}

.Detalhes .video_header iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.Detalhes .detalhes_local {

}

.Detalhes .detalhes_local label {
    text-transform: capitalize;
    margin-top: 20px;
    font-size: 16px;
    color: #788FBF
}

.Detalhes .detalhes_local h5 {
    font-size: 30px
}

.Detalhes .detalhes_local p {
    text-transform: none;
    margin-bottom: 35px;
    margin-top: 25px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.9;
}

.Detalhes .preco > div {
    width: 300px;
    margin: auto;
    background-color: white;
    border-radius: 50%;
    position: relative;
}

.Detalhes .preco > div .background {
    padding-bottom: 100%;
}

.Detalhes .preco .content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    padding-bottom: 60px;
}

.Detalhes .preco .content p {
    color: #07B9AD;
    text-align: center;
    margin: 0;
}

.Detalhes .preco .content a {
    border-radius: 10px;
    margin-top: 30px;
    color: white;
    padding: 15px;
    background-color: #07b9ad;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
}
body, html {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
}

html {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    min-height: 100%;
    overflow: hidden;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1 1
}

img, canvas {
    max-width: 100%;
}

* {
    box-sizing: border-box;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table td {
    padding: 7px;
}

.container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.container.width-100 {
    width: 100%;
}

.pull-right {
    float: right !important;
}

.cursor-pointer {
    cursor: pointer;
}

.RouteWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    background-color: #f3f2ed;
}

.RouteWrapper:not(.fullscreen) {
    padding-bottom: 73px;
}

.bottom_action {
    color: white;
    text-transform: uppercase;
    margin: 15px 0;
    text-align: center;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    display: inline-block;
    padding-bottom: env(safe-area-inset-bottom);
}

.bottom_action.disabled {
    opacity: 0;
    pointer-events: none;
}

.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
}

::-webkit-scrollbar {
    display: none;
}

hr {
    border-width: 0.5px;
    border-color: #cececd;
    margin: 0;
}
body, html {
    font-family: 'Montserrat', sans-serif;
    color: #3E3E3E;
    background-color: #07b9ad;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

p {
    margin: 0;
    margin-top: 5px;
    text-align: justify;
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 300;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.color-primary {
    color: #07B9AD
}

.color-secondary {
    color: #788FBF
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-title {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-title p, .section-title h4 {
    text-transform: uppercase;
    color: #07B9AD;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}

.section-title p {
    color: #788FBF;
    display: inline-flex;
    align-items: center;
}

.section-title p .icon {
    height: 16px;
    margin-left: 5px;
    display: inline-block;
    margin-bottom: 2px;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

.animated.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
}

@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
}

.animated.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        opacity: 0.75;
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(250px);
                transform: translateY(250px);
    }
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    50% {
        opacity: 0.75;
        -webkit-transform: translateY(100px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(250px);
    }
}

.animated.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(150px);
                transform: translateY(150px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.animated.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.animated.rotating {
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.form-action {
    background-color: white;
    border-radius: 8px;
    color: #07B9AD;
    width: 100%;
    display: block;
    text-align: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
}

.button-link {
    margin-top: 25px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: white;
    text-decoration: underline;
}

.button-link-in {
    color: white;
    text-decoration: underline;
}

