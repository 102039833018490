body, html {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
}

html {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    min-height: 100%;
    overflow: hidden;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1
}

img, canvas {
    max-width: 100%;
}

* {
    box-sizing: border-box;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table td {
    padding: 7px;
}

.container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.container.width-100 {
    width: 100%;
}

.pull-right {
    float: right !important;
}

.cursor-pointer {
    cursor: pointer;
}

.RouteWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    transition: background-color 0.3s;
    background-color: #f3f2ed;
}

.RouteWrapper:not(.fullscreen) {
    padding-bottom: 73px;
}

.bottom_action {
    color: white;
    text-transform: uppercase;
    margin: 15px 0;
    text-align: center;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;
    display: inline-block;
    padding-bottom: env(safe-area-inset-bottom);
}

.bottom_action.disabled {
    opacity: 0;
    pointer-events: none;
}

.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 100ms ease-in;
}

::-webkit-scrollbar {
    display: none;
}

hr {
    border-width: 0.5px;
    border-color: #cececd;
    margin: 0;
}