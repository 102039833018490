@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap');

body, html {
    font-family: 'Montserrat', sans-serif;
    color: #3E3E3E;
    background-color: #07b9ad;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

p {
    margin: 0;
    margin-top: 5px;
    text-align: justify;
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 300;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.color-primary {
    color: #07B9AD
}

.color-secondary {
    color: #788FBF
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-title {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-title p, .section-title h4 {
    text-transform: uppercase;
    color: #07B9AD;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}

.section-title p {
    color: #788FBF;
    display: inline-flex;
    align-items: center;
}

.section-title p .icon {
    height: 16px;
    margin-left: 5px;
    display: inline-block;
    margin-bottom: 2px;
}